var apiUrl = '';

switch (process.env.NEXT_PUBLIC_APP_ENV) {
  case 'staging':
    apiUrl = process.env.NEXT_PUBLIC_STAGING_API;
    break;

  case 'production':
    apiUrl = process.env.NEXT_PUBLIC_PRODUCTION_API;
    break;

  default:
    apiUrl = 'https://cmpapi-staging.jobsrefer.com';
}

export default {
  API_URL: apiUrl
};