var eventBus = {
  on: function on(event, callback) {
    document.addEventListener(event, function (e) {
      return callback(e.detail);
    });
  },
  dispatch: function dispatch(event) {
    var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    document.dispatchEvent(new CustomEvent(event, {
      detail: data
    }));
  },
  remove: function remove(event, callback) {
    document.removeEventListener(event, callback);
  }
};
export default eventBus;