import { create } from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import get from 'lodash/get';
import SnackbarUtils from 'utils/toast';
import { getAccessToken } from 'utils/cookie';
import { getLocalStorage } from 'utils/store';
import eventBus from 'utils/event-bus';
import ApiUrlConfig from './envConfig';
import { DEFAULT_LANGUAGE } from './constants';

enum StatusCode {
  Unauthorized = 401,
  Forbidden = 403,
  TooManyRequests = 429,
  InternalServerError = 500,
}

export const ROUTES = {
  LOGIN: `/rest-auth/login/`,
  REGISTER: `/rest-auth/registration/?source=4`,
  VERIFY_EMAIL: `/rest-auth/registration/verify-email/`,
  FORGOT_PASSWORD: `/rest-auth/password/reset/`,
  CREATE_PASSWORD: `/rest-auth/password/reset/confirm/?source=4`,
  RESEND_CONFIRMATION_EMAIL: `/resend-confirmation/`,
  ONBOARDING_REQUIREMENT: `/onboarding-requirement/`,
  PROFILE: `/rest-auth/user/`,
  EDUCATION: `/education/`,
  EXPERIENCE: `/experience/`,
  ORDERING: `/set-ordering/`,
  MAJOR: `/major/`,
  LIST_CV_TEMPLATE: `/cv-template/`,
  MY_CV_TEMPLATE: `/mycv-template/`,
  USER_PROFILE: `/rest-auth/user/`,
  CHECK_VERIFY_EMAIL: `/rest-auth/registration/check-verify-email/`,
  CHANGE_PASSWORD: `/rest-auth/password/change/`,
  CV_TRANSACTION: '/cv-transaction/?succeed=true',
  PURCHASE: (id: number) => `/cv-template/${id}/purchase/`,
  CAPTURE_CV_TRANSACTION: (transactionId: string) => `/cv-transaction/${transactionId}/capture/`,
  CANCEL_CV_TRANSACTION: (transactionId: string) => `/cv-transaction/${transactionId}/cancel/`,
  TERM: `/candidate-tnc/`,
  POLICY: `privacy-policy/`,
  SAMPLE_CV: `/sample-cv/`,
  CV_CATEGORY: `/cv-category/`,
  OCCUPATION: `/occupation/`,
  OCCUPATION_DETAILS: (occupationId: number) => `/occupation/${occupationId}/`,
  LOGOUT: `/rest-auth/logout/`,
  SHARED_CV: (username, cvId) => `/shared-cv/${username}/${cvId}/`,
  JOB_SCOPE_LIST: `/job-scope/`,
  DEGREE_LIST: `/degree/`,
  SCHOOL_LIST: `/university/`,
  JOB_TYPE_LIST: `/jobtype/`,
  COMPANY_LIST: `/company/`,
  REFERENCE: `/reference/`,
  INTEREST_LIST: `/interest/`,
  CERTIFICATION: `/certification/`,
  VOLUNTEER: `/volunteer/`,
  LANGUAGE: `/language/`,
  CANDIDATE_LANGUAGE: `/candidate-language/`,
  SKILL_LIST: `/skill/`,
  CONTACT_US: `/urbancv-contact-us/`,
  FACEBOOK_LOGIN: `/rest-auth/facebook/`,
  LINKEDIN_LOGIN: `/rest-auth/linkedin/`,
  REFERRAL_DETAIL: '/referral-detail',
  ADD_CLICKS: '/add-clicks/',
};

export const API = create({
  baseURL: ApiUrlConfig.API_URL,
});

const AxiosRequest = (axiosConfig: AxiosRequestConfig): AxiosRequestConfig => {
  try {
    const token = getAccessToken();
    const config = axiosConfig;
    const language = getLocalStorage('language') || DEFAULT_LANGUAGE;
    if (token != null) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Accept-Language'] = language;
    return config;
  } catch (error: App.Any) {
    throw new Error(error);
  }
};

API.axiosInstance.interceptors.request.use(AxiosRequest, (error) => Promise.reject(error));

API.axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const status: number = get(error, 'response.status');
    switch (status) {
      case StatusCode.Unauthorized: {
        let message = get(error, 'response.data.detail');
        message = message ? 'Login session has expired.' : '';
        const notistackDiv = document.getElementById('notistack-snackbar');
        const isExistNotify = notistackDiv && notistackDiv.textContent === message;
        if (message && !isExistNotify) {
          SnackbarUtils.toast(message, { variant: 'error', autoHideDuration: 6000 });
          eventBus.dispatch('logout');
        }
        break;
      }
      case StatusCode.Forbidden: {
        break;
      }
      case StatusCode.InternalServerError: {
        break;
      }
      case StatusCode.TooManyRequests: {
        break;
      }
    }
    return error;
  },
);
